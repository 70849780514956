angular.module('dcfCtrl', ['angularCharts', 'userService'])
    .controller('dcfController', function($scope, User) {
        $scope.config = {
            colors: [
                'rgb(70,132,238)'
            ],
            title: 'Net Cash Flow',
            tooltips: true,
            labels: false
        };

        $scope.data = {
            series: ['Net Cash Flow'],
            data: []
        };

        var vm = this;
        vm.userToLoad = ($('#user_id').length ? $('#user_id').val() : null);
        vm.postLaunch = 3;
        vm.totalYears = 6;
        vm.answers = {};

        vm.revenuePeriodOptions = [
            {
                value: 1,
                label: 'It is ready to market now'
            },
            {
                value: 1,
                label: '1 Year or less'
            },
            {
                value: 2,
                label: '2 Years or less'
            },
            {
                value: 3,
                label: '3 Years or less'
            },
            {
                value: 4,
                label: '4 Years or less'
            },
            {
                value: 5,
                label: '5 Years or less'
            }
        ];

        vm.revenueDriverOptions = [
            {
                value: 'Users',
                single: 'user',
                action: 'acquiring',
                label: 'product or service delivery for'
            },
            {
                value: 'Subscribers',
                single: 'subscriber',
                action: 'acquiring',
                label: 'product or service delivery for'
            },
            {
                value: 'Customers',
                single: 'customer',
                action: 'acquiring',
                label: 'product or service delivery for'
            },
            {
                value: 'Production',
                single: 'unit of production',
                action: 'developing',
                label: 'delivering'
            },
            {
                value: 'Viewers',
                single: 'viewer',
                action: 'acquiring',
                label: 'product or service delivery for'
            }
        ];

        vm.yearsSet = function() {
            return vm.answers.benefits == '1' && vm.answers.benefitsPeriod > 0;
        };

        vm.years = function(years) {
            return years == 1 ? 'year' : 'years';
        };

        vm.volumesPeakPeriodOptions = [];
        for (var period = 1; period <= 10; period++) {
            vm.volumesPeakPeriodOptions.push({
                value: period,
                label: period + ' ' + vm.years(period) + ' or less'
            });
        };

        vm.varianceOptions = [
            {
                value: 7.5,
                label: '+/- 15%'
            },
            {
                value: 10,
                label: '+/- 25%'
            },
            {
                value: 12.5,
                label: '+/- 35%'
            },
            {
                value: 15,
                label: '+/- 45%'
            },
            {
                value: 20,
                label: '+/- 50% or greater'
            }
        ];

        vm.answerOptions = {
            revenuePeriod: vm.revenuePeriodOptions,
            revenueDriver: vm.revenueDriverOptions,
            volumesPeakPeriod: vm.volumesPeakPeriodOptions,
            variance: vm.varianceOptions
        };

        vm.findByValue = function(arr, val) {
            for (var i = 0; i < arr.length; i++) {
                var element = arr[i];

                if (element.value == val) {
                    return element;
                }
            };

            return null;
        };

        vm.getRange = function(number) {
            number = number || vm.totalYears;

            return new Array(number);
        };

        vm.endYears = function() {
            if (! vm.answers.revenuePeriod) return 0;

            var evaluationPeriod = vm.answers.revenuePeriod.value + vm.postLaunch;

            return Math.max(evaluationPeriod, vm.answers.benefitsPeriod);
        };

        vm.isEndFlag = function(year) {
            return year <= vm.endYears();
        };

        vm.growthPeriod = function() {
            if (! vm.answers.volumesPeakPeriod) return 0;

            return vm.answers.volumesPeakPeriod.value - vm.answers.revenuePeriod.value;
        };

        vm.getGrowthPA = function() {
            var result = vm.answers.volumesPerAnnum / (vm.growthPeriod() + 1);

            if (! result) return 0;

            return result;
        };

        vm.applyTotal = function(func) {
            var total = 0;

            for (var year = 1; year <= vm.totalYears; year++) {
                total += func(year);
            };

            return total;
        }

        vm.getRevenueDriversAcquisition = function(year) {
            if (vm.answers.revenuePeriod && year >= vm.answers.revenuePeriod.value) {
                var yearsWithDrivers = year - vm.answers.revenuePeriod.value + 1;

                return vm.getGrowthPA() * yearsWithDrivers;
            }

            return 0;
        };

        vm.getRevenue = function(year) {
            return vm.answers.revenuePerSingle * vm.getRevenueDriversAcquisition(year);
        };

        vm.getTotalRevenue = function() {
            return vm.applyTotal(vm.getRevenue);
        };

        vm.getCapitalCosts = function(year) {
            if (year == 1) return -1 * vm.answers.moneyRequired;

            return 0;
        };

        vm.getTotalCapitalCosts = function() {
            return vm.applyTotal(vm.getCapitalCosts);
        };

        vm.getOperatingCosts = function(year) {
            return -1 * vm.getRevenueDriversAcquisition(year) * (vm.answers.costOfEach + vm.answers.costOfDeliveryForEach);
        };

        vm.getTotalOperatingCosts = function() {
            return vm.applyTotal(vm.getOperatingCosts);
        };

        vm.getNetCashFlow = function(year) {
            return vm.getRevenue(year) + vm.getCapitalCosts(year) + vm.getOperatingCosts(year);
        };

        vm.getTotalNetCashFlow = function() {
            return vm.applyTotal(vm.getNetCashFlow);
        };

        vm.getNetCashFlowAsArray = function() {
            var values = [];

            for (var year = 1; year <= vm.totalYears; year++) {
                values.push(vm.getNetCashFlow(year));
            };

            $scope.data.data = vm.getChartsData(values);

            return values;
        };

        vm.getIRR = function(guess) {
            var values  = vm.getNetCashFlowAsArray();
            // Credits: algorithm inspired by Apache OpenOffice

            // Calculates the resulting amount
            var irrResult = function(values, dates, rate) {
                var r = rate + 1;
                var result = values[0];

                for (var i = 1; i < values.length; i++) {
                    result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365);
                }

                return result;
            }

            // Calculates the first derivation
            var irrResultDeriv = function(values, dates, rate) {
                var r = rate + 1;
                var result = 0;

                for (var i = 1; i < values.length; i++) {
                    var frac = (dates[i] - dates[0]) / 365;
                    result -= frac * values[i] / Math.pow(r, frac + 1);
                }

                return result;
            }

            // Initialize dates and check that values contains at least one positive value and one negative value
            var dates = [];
            var positive = false;
            var negative = false;
            for (var i = 0; i < values.length; i++) {
                dates[i] = (i === 0) ? 0 : dates[i - 1] + 365;
                if (values[i] > 0) positive = true;
                if (values[i] < 0) negative = true;
            }

            // Return error if values does not contain at least one positive value and one negative value
            if (!positive || !negative) return '#NUM!';

            // Initialize guess and resultRate
            var guess = (typeof guess === 'undefined') ? 0.1 : guess;
            var resultRate = guess;

            // Set maximum epsilon for end of iteration
            var epsMax = 1e-10;

            // Set maximum number of iterations
            var iterMax = 50;

            // Implement Newton's method
            var newRate, epsRate, resultValue;
            var iteration = 0;
            var contLoop = true;
            do {
                resultValue = irrResult(values, dates, resultRate);
                newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
                epsRate = Math.abs(newRate - resultRate);
                resultRate = newRate;
                contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax);
            } while(contLoop && (++iteration < iterMax));

            if (contLoop || resultRate === Infinity) return '#NUM!';

            // Return internal rate of return
            return Math.round(resultRate * 100);
        };

        vm.getNPV = function() {
            var finance = new Finance();
            args = vm.getNetCashFlowAsArray();
            args.unshift(vm.answers.variance.value);

            return finance.NPV.apply(null, args);
        };

        vm.getChartsData = function(values) {
            var data = [];

            for (var i = 0; i < values.length; i++) {
                var year  = i + 1,
                    value = values[i];

                data.push({
                    x: year,
                    y: [value]
                });
            };

            return data;
        };

        if (vm.userToLoad) {
            User.getDcf(vm.userToLoad)
                .then(function(data) {
                    for (var field in data.data) {
                        var label = field.toCamel()
                            val   = data.data[field];

                        if (typeof vm.answerOptions[label] !== 'undefined') {
                            val = vm.findByValue(vm.answerOptions[label], val);
                        }
                        else if (typeof val == 'string' && val.indexOf('.') !== -1) {
                            val = parseFloat(val);
                        }

                        vm.answers[label] = val;
                    }
                });
        }

        // Default values
        vm.answers = {
            benefits : '1',
            benefitsPeriod : 2,
            revenuePeriod : {
                value: 3,
                label: '3 Years or less'
            },
            moneyRequired : 100000,
            revenueDriver : {
                value: 'Users',
                single: 'user',
                action: 'acquiring',
                label: 'product or service delivery for'
            },
            revenuePerSingle : 400,
            volumesPerAnnum : 3200,
            volumesPeakPeriod : {
                value: 10,
                label: '10 years or less'
            },
            costOfEach : 250,
            costOfDeliveryForEach : 120,
            variance : {
                value: 7.5,
                label: '+/- 15%'
            }
        };
    });