angular.module('userService', [])

.factory('User', function($http) {
    // create a new object
    var userFactory = {};

    userFactory.getDcf = function(id) {
        return $http.get('/admin/leads/' + id);
    };

    return userFactory;
});